import React from "react"
import styled from "styled-components"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { medWrapper } from "../styles/helpers"

const NotFoundPage = props => {
  return (
    <Layout>
      <Seo
        title="404: Not found"
        description="404: Not found"
        //metaImg={seoInfo.seoFields.swbThemeImage.localFile.relativePath}
        location={props.location.pathname}
      />
      <SectionStyled>
        <div className="wrapper">
          <h1>404: Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
      </SectionStyled>
    </Layout>
  )
}

const SectionStyled = styled.section`
  .wrapper {
    ${medWrapper};
    min-height: 55vh;
  }

  h1 {
    width: 100%;
    text-align: center;
  }

  p {
    width: 100%;
    text-align: center;
  }
`

export default NotFoundPage
